import { createSlice, current } from "@reduxjs/toolkit";
import { revertCart } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import getDiscount from "../../helper/discountHelper";

let initialState = {
  cartItems: [],
  totalPrice: 0,
  extraPrice: 0,
  itemsPrice: 0,
  discountPercent: 0,
  discountPrice: 0
};
let cartSlice = createSlice({
  name: "cartslice",
  extraReducers: (builder) => {
    return builder.addCase(revertCart, () => {
      storage.removeItem("persist:root");
      return initialState;
    });
  },
  initialState,

  reducers: {
    addToCart: (state, action) => {
      state.cartItems = [...state.cartItems, action.payload];
      state.totalPrice =
        state.totalPrice +
        Number(action.payload.totalPrice > 0 ? action.payload.totalPrice : 0);
      state.extraPrice =
        state.extraPrice +
        Number(action.payload.extraPrice > 0 ? action.payload.extraPrice : 0);
      state.itemsPrice =
        state.itemsPrice +
        Number(action.payload.price > 0 ? action.payload.price : 0);
      state.discountPercent = action.payload.discountCalculated
        ? action.payload.discountCalculated
        : 0;
      state.discountPrice = action.payload.discountPrice
        ? action.payload.discountPrice
        : 0;
    },
    removeProductFromCart: (state, action) => {
      let products = [...current(state.cartItems)];
      let item = products[action.payload.index];
      state.itemsPrice = state.itemsPrice - item.price;

      // 11.01.23 edited added plus minus in cart
      let qquantity = item.quantity;
      let thisItemPrice = item.incPrice;
      let thisTotalPrice = item.totalPrice;

      qquantity = qquantity - 1;
      thisTotalPrice = qquantity * item.incPrice;
      let updatedItems = {
        ...item,
        quantity: qquantity,
        totalPrice: thisTotalPrice
      };

      products[action.payload.index] = updatedItems;
      state.cartItems = products;

      state.itemsPrice = state.itemsPrice - item.price;
      state.totalPrice = state.totalPrice - thisItemPrice;

      if (qquantity < 1) {
        state.cartItems.splice(action.payload.index, 1);
      }

      //Update discount when product removed from cart
      // console.log("--total, itemprice---", state.totalPrice, thisItemPrice);

      ///calc discount
      let { discountCalculated, discountPrice } = getDiscount(
        state.totalPrice,
        action.payload.restaurant,
        action.payload.orderType
      );

      // console.log("---Discounts----", discountCalculated, discountPrice);

      state.discountPrice = discountPrice;
      state.discountPercent = discountCalculated;
    },

    removeExtraItemFromCart: (state, action) => {
      let products = [...current(state.cartItems)];
      let item = products[action.payload.productIndex];
      let extraItems = item.extraItems ? [...item.extraItems] : [];
      let extraProducts = extraItems[action.payload.extraIndex];
      state.totalPrice = state.totalPrice - extraProducts.incPrice;
      state.extraPrice = state.extraPrice - extraProducts.incPrice;
      let oldExtraItems = [...item.extraItems];

      oldExtraItems.splice(action.payload.extraIndex, 1);
      let updatedItem = { ...item, extraItems: oldExtraItems };

      products[action.payload.productIndex] = updatedItem;

      state.cartItems = products;
    },

    emptyCart: (state, action) => {
      state.cartItems = [];
      state.totalPrice = 0;
      state.extraPrice = 0;
      state.itemsPrice = 0;
      state.discountPercent = 0;
      state.discountPrice = 0;
    }
  }
});

export default cartSlice.reducer;
export const cartAction = cartSlice.actions;
