import React, { useState } from "react";
import axios from "axios";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

import BottomFooter from "../Footer/BottomFooter";

import { Row, Col, Divider } from "antd";
import { FloatingLabel, Button, Form, Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import circleLogin from "../../assets/circleLogin.png";
import pinLogin from "../../assets/pinLogin.png";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Verify() {
  const dispatch = useDispatch();

  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let otp = e.target.inputOtp.value;

    if (otp == "" || phoneNumber == "") {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Telefonnummer og bekreftelseskode kreves.");
    } else {
      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        otp: otp,
        phone: phoneNumber
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/otpverifcation`, formData)
        .then((response) => {
          // console.log("---response server---", response);

          if (response.data.status == 200) {
            // console.log("----ok----");

            setShowAlert(true);
            setAlertType("success");
            setAlertMsg(response.data.message);
            window.location = "login";
          } else {
            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(error.response.data.message);
        });
    }
  };

  const commonVerify = () => {
    return (
      <>
        <Row
          className="d-flex flex-column align-items-center justify-content-center logBox"
          style={{ borderRadius: "20px" }}
        >
          {" "}
          <Col span={24} className="text-center width-100">
            {" "}
            <h4 class="font-18 font-700">EzyGo</h4>
            <h4
              class="font-18 font-700 mt-1 d-flex align-items-center justify-content-center"
              style={{ color: "#0066CC " }}
            >
              <img src={circleLogin} loading="lazy" />
              &nbsp; Forenklet for deg!&nbsp;
              <img src={pinLogin} loading="lazy" />
            </h4>
            <Divider>Bekreft kontoen din</Divider>
          </Col>
          <Col span={24} className="text-center width-100">
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Telefonnummer*"
                  >
                    <Form.Control
                      required
                      type="number"
                      placeholder="Telefonnummer"
                      name="inputPhone"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="OTP*">
                    <Form.Control
                      required
                      type="number"
                      placeholder="OTP"
                      name="inputOtp"
                    />
                  </FloatingLabel>
                  <p className="mb-4 pt-2 text-left">
                    * Vennligst skriv inn bekreftelseskoden du har mottatt på
                    ditt registrerte mobilnummer.
                  </p>
                </Col>
              </Form.Group>

              {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}

              <Button
                variant="primary"
                type="submit"
                className="width-100 py-2 border-radius-10"
              >
                Verifiser konto
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {" "}
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages  p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col s md={9} xl={6} className="pb-3">
              {commonVerify()}
            </Col>
          </div>
        </section>
      )}
      {isMobile && (
        <section
          className="verifyBg p-2 d-flex align-items-center justify-content-center"
          style={{ height: "95vh" }}
        >
          {commonVerify()}
        </section>
      )}
      <BottomFooter />
    </>
  );
}
