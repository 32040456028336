import { Route } from "react-router";
import axios from "axios";

export const getCurrentLocationLatLng = () => {
  var timeoutVal = 10 * 1000 * 1000;
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        return resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      function (error) {
        return reject(error.message);
      },
      { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 }
    );
  });
};

export function getAddressFromlatLong(lat, lng) {
  return new Promise(async (resolve, reject) => {
    // console.log("---Getting Address--", lng, lat);
    try {
      let response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?&access_token=pk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbGY2cXF3YXQwbmJoM3NubnRhYW5mbXpwIn0.qeCkkjp_WXmXrekPzF7wrQ`
      );
      // console.log(`Current Location:`, response.data.features[0].place_name);

      let address = response.data.features[0].place_name;
      let locDetails = "";

      return resolve({ address, lat: lat, lng: lng, locDetails });
    } catch (error) {
      // console.log("---Error in getting Address----", error);
      return reject("Error in fetching location", error);
    }
  });
}

function getAddressDetails(addressCompoent) {
  let streeNumber = "";
  let houseNumber = "";
  let line1 = "";
  let line2 = "";
  let line = "";
  let line3 = "";
  let line4 = "";
  let landmark = "";
  let city = "";
  let state = "";
  let country = "";
  let postal_code = "";
  let postalCodeSuffix = "";

  addressCompoent.forEach((sin) => {
    let locality = sin.types[0];
    let locality1 = sin.types[1];
    let locality3 = sin.types[2];

    switch (locality) {
      case "street_number":
        streeNumber = sin.long_name;
        break;
      //"premise"
      case "premise":
        line1 = sin.long_name;
        break;
      case "route":
        line2 = sin.long_name;
        break;
      case "political":
        line3 = line3 + " " + sin.long_name;
        break;
      case "locality":
        line3 = line3 + " " + sin.long_name;
        break;

      case "postal_code":
        postal_code = sin.long_name;
        break;

      case "postal_code_suffix":
        postalCodeSuffix = sin.long_name;
        break;

      case "administrative_area_level_2":
        city = sin.long_name;
        break;

      case "administrative_area_level_1":
        state = sin.long_name;
        break;

      case "country":
        country = sin.long_name;
        break;
    }
  });
  return {
    streetNumber: streeNumber,
    line1: line1,
    line2: line2,
    line3: line3,
    city: city,
    state: state,
    country: country,
    pincode: postal_code
  };
}
export default getAddressDetails;
