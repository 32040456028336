import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../store/slices/userslices";
import { isMobile } from "react-device-detect";
import { Card, Row, Col, Button, Avatar, Divider } from "antd";
import { s3url } from "../../helper/endpoint";
import ProfileIcon from "../../assets/profileFallBack.png";
import changePass from "../../assets/mobile/changepass.png";
import { PoweroffOutlined, EditOutlined } from "@ant-design/icons";
import BottomFooter from "../Footer/BottomFooter";

export default function ProfileInABoard() {
  const userprofileImage = localStorage.getItem("profileImage");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.userReducer);
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());
    if (!user.token) {
      navigate("/login");
    }

    window.location.reload();
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  const content = () => {
    return (
      <div className="width-100 px-3">
        {/* Profile Section */}
        <Row className="flex-column align-items-center justify-content-center mb-3  pt-3">
          <Avatar
            size={80}
            src={
              userprofileImage != ""
                ? `${s3url}/${userprofileImage}`
                : ProfileIcon
            }
            style={{
              marginBottom: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
            }}
            alt={`${user.firstName}`}
          />
          <h2 style={{ color: "#1890ff", margin: 0, fontWeight: "bold" }}>
            {user.firstName} {user.lastName}
          </h2>
          <p style={{ color: "#666", marginBottom: "16px" }}>{user.email}</p>
          <Button
            type="primary"
            className="border-radius-10 font-700 d-flex justify-content-center align-items-center"
            icon={<EditOutlined />}
            onClick={() => navigate("/editProfile")}
          >
            Rediger profil
          </Button>
        </Row>
        {/* <Row> */}
        <Divider>Preferanser</Divider>
        <Card
          className="border-radius-10"
          onClick={() => navigate("/changePassword")}
          hoverable
          style={{
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease"
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "scale(1.05)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          cover={
            <img
              alt="Endre passord"
              src={changePass}
              style={{
                padding: "16px",
                height: "150px",
                objectFit: "contain"
              }}
            />
          }
        >
          <p
            style={{
              fontWeight: "bold",
              color: "#1890ff",
              margin: 0
            }}
          >
            Endre passord
          </p>
        </Card>
      </div>
    );
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll px-3">
          <div
            className="d-flex flex-column justify-content-between align-items-center spreadedShadow border-radius-10 mb-4 mt-3 width-100"
            // style={{ height: "fit-content" }}
          >
            {content()}

            <Button
              icon={<PoweroffOutlined />}
              onClick={refreshAfterLogout}
              className="text-white border-radius-10 font-700 px-3 d-flex align-items-center spreadedShadow width-100"
              style={{
                border: "none",
                backgroundColor: "#d9534f",
                cursor: "pointer"
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#ffc2c2")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffd1d1")}
            >
              Logg ut
            </Button>
          </div>
        </Row>
      ) : (
        <div style={{ marginTop: "100px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="commonShadowAndRound p-5">
              {content()}
              <div className="d-flex justify-content-center align-items-center py-2 px-3 mb-4 mt-5">
                <Button
                  icon={<PoweroffOutlined />}
                  onClick={refreshAfterLogout}
                  className="text-white border-radius-50 font-700 px-3 d-flex align-items-center spreadedShadow"
                  style={{
                    border: "none",
                    backgroundColor: "#d9534f",
                    cursor: "pointer"
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ffc2c2")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#ffd1d1")
                  }
                >
                  Logg ut
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
