import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import axios from "axios";

import coins from "../../assets/tfl-coins-website.webp";
import { Row, Col, Table, Divider, Popconfirm, Collapse } from "antd";

import { Button } from "react-bootstrap";
import coinsText from "../../assets/tfl coin text.webp";
import BottomFooter from "../Footer/BottomFooter";
function TFLCoinExchange() {
  const [searchParams] = useSearchParams();
  let userID = localStorage.getItem("userId");
  let navigate = useNavigate("");
  const { Panel } = Collapse;
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coinCode, setCoinCode] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [userPoints, setUserPoints] = useState("0");
  const [pointsRedeemed, setPointsRedeemed] = useState("0");
  const [userPointData, setUserPointData] = useState([]);

  let user = useSelector((state) => state.userReducer);
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getCoupons();
    getPoints();
  }, []);

  const getPoints = async () => {
    let cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);
    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getuserpoints`, cardHistoryData)
      .then((response) => {
        // console.log("response", response.data.data.points);
        localStorage.setItem("userPoints", response.data.data.points);
        setUserPoints(response.data.data.points);
        setPointsRedeemed(response.data.data.pointsRedeemed);
        setUserPointData(response.data.data.pointsArr);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("---error user---", error);
      });
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);

    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getusercoupons`, cardHistoryData)
      .then((response) => {
        setCouponData(response.data.data.coupons);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("---error user---", error);
      });
  };
  const columnsData = [
    {
      title: "Gavekort i NOK",
      dataIndex: "nok",
      key: "giftCardInNOK"
    },
    {
      title: "Antall mynter",
      dataIndex: "points",
      key: "coins"
    },
    {
      title: "Handling",
      key: "action",
      render: (_, record) =>
        userPointData.length >= 1 ? (
          <Popconfirm
            title={`Vil du løse inn ${record.points} mynter for ${record.nok} NOK kupong?`}
            onConfirm={() => handleRedeem(record._id)}
            disabled={userPoints === 0}
          >
            <Button
              type="primary"
              disabled={userPoints === 0}
              className="width-100"
            >
              Løs inn
            </Button>
          </Popconfirm>
        ) : null
    }
  ];
  const handleRedeem = (id) => {
    // {
    //   console.log("record key", id);
    // }
    let payload = {
      appUserId: userID,
      redeemId: id
    };
    async function fetchData() {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/treeDrive/appuser/redeempoints`,
          payload
        );

        if (response.data.status == 500) {
          alert(response.data.message);
        } else if (response.data.status == 403) {
          alert(
            "Dette nummeret har allerede kjøpt maksimalt 3 gavekort." ||
              response.data.message
          );
        } else {
          alert(response.data.message);
        }

        setCoinCode(response.data.data.coupon.coupanCode);
        // console.log("response is getting", response.data.data.coupon);
        setOpenModal(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const content = () => {
    return (
      <>
        <Row className="justify-content-center p-3">
          <Row className="bg-gradient mb-3 p-3 border-radius-10">
            <Col
              span={15}
              className="d-flex align-items-center font-700 mt-1 text-white font-18"
            >
              Få gavekort med dine TFL-mynter!
            </Col>
            <Col span={8} className="d-flex align-items-end mt-1">
              <img
                src={coins}
                loading="lazy"
                alt="coins"
                width="100%"
                height={"100%"}
              />
            </Col>
          </Row>
          <Col
            span={24}
            className=" pointsDisplay d-flex flex-column justify-content-center align-items-start"
          >
            <h5 style={{ color: "#316cbb" }}>
              Tilgjengelige mynter: {userPoints}
            </h5>
            <h6>Brukte mynter: {pointsRedeemed}</h6>
            <h6>
              Totalt opptjente mynter: <b>{userPoints + pointsRedeemed}</b>
            </h6>
          </Col>
          <Col span={24}>
            {" "}
            <i>
              <b>
                <p>
                  *Dine mynter vil bli oppdatert innen én time etter
                  ordrebekreftelsen.
                </p>
              </b>
            </i>
          </Col>
          <Divider />
          <Col span={24} className="d-flex justify-content-center">
            <h5>
              <br />
              <b>Innløsningsordning</b>
            </h5>
            <br />
          </Col>
          <Col span={24}>
            <Table
              className="pointsTable"
              columns={columnsData}
              dataSource={userPointData}
              bordered
            />
          </Col>
          <Col span={24} className="pt-2">
            <p>
              <i>
                <u>*Sjekk gavekortene dine i gavekortfanen ovenfor!</u>
              </i>
            </p>
          </Col>
        </Row>
        <Row className="px-3 bottomThought pt-4 pb-5">
          <Col span={24} className="mt-3 sansita-black">
            <p>Jo mer du samler. Jo mer tjener du!</p>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      {isMobile && <div className="vh90andScroll">{content()}</div>}
      {!isMobile && (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col span={8} className="commonShadowAndRound my-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
export default TFLCoinExchange;
