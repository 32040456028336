import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/landscapelogo.webp";
import ProfileIcon from "../../assets/profileFallBack.png"; // Static fallback image
import "aos/dist/aos.css";
import { customerAction } from "../../store/slices/customerslices";
import { s3url } from "../../helper/endpoint";

export default function MobileNavTop() {
  const user = useSelector((state) => state.userReducer);
  let userprofileImage = localStorage.getItem("profileImage");

  const profileImageSrc =
    userprofileImage == "" ? ProfileIcon : `${s3url}/${userprofileImage}`;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customerAction.removeCustomer());
  }, [dispatch]);

  return (
    <Row className="position-fixed mobileNavTop width-100 px-3 py-2">
      <Col span={21}>
        <img src={logo} loading="lazy" alt="logo" className="logoSize" />
      </Col>
      {user.firstName && (
        <Col span={3} className="d-flex justify-content-end">
          <NavLink
            to={user.token ? "/shortCutToAll" : "/login"}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              loading="lazy"
              alt={`${user.firstName || "Profile"}`}
              src={profileImageSrc}
              width={40}
              height={40}
              className="border-radius-50"
            />
          </NavLink>
        </Col>
      )}
    </Row>
  );
}
