import { Row, Col, Divider } from "antd";
const TotalTaxCalculatin = (props) => {
  return (
    <>
      <Divider plain className="pt-3">
        Fakturadetaljer
      </Divider>
      <Row>
        <Col span={12}>
          <h6 className="itemTotal">Subtotal</h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal billText">
            {(
              props.cart.totalPrice -
              (props.cart.totalPrice -
                (100 * props.cart.totalPrice) / (100 + 15))
            ).toFixed(2)}
          </h6>
        </Col>
      </Row>

      <Row className="pt-1">
        <Col span={12}>
          <h6 className="itemTotal">Mva</h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal billText">
            {(
              props.cart.totalPrice -
              (100 * props.cart.totalPrice) / (100 + 15)
            ).toFixed(2)}
          </h6>
        </Col>
      </Row>

      {props.cart.discountPrice === 0 && props.cart.discountPercent === 0 ? (
        <></>
      ) : (
        <Row className="pt-1">
          <Col span={12}>
            <h6 className="itemTotal">
              Rabatt({props.cart.discountPercent + "%"})
            </h6>
          </Col>
          <Col span={12} className="text-right">
            <h6 className="itemTotal billText">
              {props.cart.discountPrice.toFixed(2)}
            </h6>
          </Col>
        </Row>
      )}

      <Row className="pt-1">
        <Col span={12}>
          <h6 className="itemTotal mb-0">Total</h6>
        </Col>
        <Col span={12} className="text-right">
          <h6 className="itemTotal mb-0 billText">
            {props.cart.totalPrice.toFixed(2)}
          </h6>
        </Col>
      </Row>
    </>
  );
};

export default TotalTaxCalculatin;
