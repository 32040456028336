import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { couponAction } from "../slices/couponslices";
import { errorAction } from "../slices/errorSlices";
export const saveCoupon = (payload) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        `${apiUrl}${tDriveEndpoint}tdlcoupon/tflcoupon`,
        payload
      );
      if (response.status == 200) {
        await dispatch(couponAction.saveCoupon(response.data.data.id));
        window.location = response.data.data.url;
      } else if (response.status == 201) {
        return response.data;
      } else if (response.status == 400) {
        return response.data;
      }
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};

export const checkCouponPaymentStatus = (
  ordrId,
  transaction_id,
  transactionObj,
  responseCode
) => {
  return async (dispatch) => {
    if (responseCode != "A01" && transactionObj.vendorName == "TDIPay") {
      dispatch(couponAction.updateCouponReducer(false));

      dispatch(
        errorAction.saveError({
          errorMessage: "Payment failed",
          isError: true
        })
      );

      return;
    }
    try {
      let response = await axios.post(
        apiUrl + tDriveEndpoint + "treedrivepayment/orderpaymentcheckcoupon",
        {
          transaction_id: transaction_id,
          orderId: ordrId,
          transactionObj: transactionObj
        }
      );

      if (response.data.status == 200) {
        if (
          response.data.message == "AUTHORIZED" ||
          response.data.message == "CAPTURED"
        ) {
          dispatch(couponAction.updateCouponReducer(true));
        }
      }
    } catch (error) {
      dispatch(couponAction.updateCouponReducer(false));

      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};

export const getCouponValue = (payload) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        `${apiUrl}${tDriveEndpoint}tdlcoupon/gettflcouponvalue`,
        payload
      );

      if (response.data.status == 200 && response.data.data.coupanValue > 0) {
        dispatch(
          couponAction.updateCouponValue({
            cpValue: response.data.data.remAmtForThisMonth,
            // cpValue: response.data.data.coupanValue,
            cpCode: payload.coupanCode
          })
        );
      } else {
        dispatch(
          couponAction.updateCouponValue({
            cpValue: 0,
            cpCode: ""
          })
        );
      }

      return response.data.message; // Return the message
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
      throw error; // Rethrow error to handle it in the calling function
    }
  };
};

export const saveCoupons = saveCoupon;
