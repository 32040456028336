import { Empty } from "antd";
import React from "react";
import searchGif from "../../assets/search.gif";
import BottomFooter from "../Footer/BottomFooter";

const App = () => (
  <>
    {" "}
    <Empty
      className="searchagain-img d-flex flex-column mb-5 justify-content-center align-items-center"
      image={searchGif}
      imageStyle={{
        height: 200
      }}
      description={
        <>
          {" "}
          <span className="font-24" style={{ lineHeight: "25px" }}>
            Vi finner ikke hvor du er, for å vise <br />
            deg nærmeste restauranter
            <br /> vennligst velg din posisjon
          </span>
        </>
      }
    />
    <BottomFooter />
  </>
);
export default App;
