import LocationModal from "../../../containers/Modal/LocationModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";

const MapForm = () => {
  let currentLocation = useSelector((state) => state.locationReducer);
  let [showLocationModal, setShowLocationModal] = useState(false);
  const onShowCancelModal = () => {
    setShowLocationModal(false);
  };
  return (
    <>
      <Row className="map-row px-3 py-2 border-radius-50">
        <Col span={18}>
          <p className="mb-0">{currentLocation.locationAddress}</p>
        </Col>{" "}
        <Col span={6}>
          {" "}
          <a
            className="change-btn"
            onClick={() => {
              setShowLocationModal(true);
            }}
          >
            Bekrefte
          </a>
        </Col>
      </Row>
      {showLocationModal && (
        <LocationModal
          onShowCancelModal={onShowCancelModal}
          isDirection={true}
        ></LocationModal>
      )}
    </>
  );
};

export default MapForm;
