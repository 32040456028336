import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./mobilenavbar.css";
import logo from "../../assets/landscapelogo.webp";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Input } from "antd";
import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

const { Search } = Input;

function PrivacyHeader(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
      dispatch(revertCou());
    }
  }, [location.pathname]);

  let navigate = useNavigate();
  return (
    <>
      <nav className="navbar">
          <div className="nav-container">
            <NavLink to="/home" className="nav-logo col-1">
              <img src={logo} alt="logo" className="logoSize" height={"50px"} />
            </NavLink>

            <div className="linksNAv">
              <a
                className="aboutNavLink"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                Hjem
              </a>
            </div>
          </div>
      </nav>
    </>
  );
}

export default PrivacyHeader;
