import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { errorAction } from "../slices/errorSlices";

import { rootAction } from "../slices/rootSlices";
const orderCreate = (
  cart,
  brachId,
  userLoction,
  driveOut,
  customerId,
  orderType,
  appliedCouponCode,
  paymentMode,
  requestedTime
) => {
  let location = { ...userLoction, disRestToCus: driveOut.disRestToCus };
  let items = cart.cartItems.map((sinItm) => {
    let extraItems = sinItm.extraItems.map((exItm) => {
      return exItm._id;
    });

    return {
      _id: sinItm._id,
      extraItems: extraItems,
      extraInstructions: sinItm.extraInstructions,
      ingredents: sinItm.ingredents,
      ingredentsDesc: sinItm.ingredentsDesc,
      instruction: sinItm.instruction,
      instructionIds: sinItm.instructionIds,
      quantity: sinItm.quantity
    };
  });

  return async (dispatch) => {
    try {
      let prod = await axios.post(
        apiUrl + tDriveEndpoint + "treedrivepayment/createorderweb",
        {
          items,
          branchId: brachId,
          address: location,
          customerId: customerId._id,
          orderType: orderType,
          couponCode: appliedCouponCode,
          paymentMode: paymentMode,
          phoneNumber: customerId.phone,
          requestedTime: requestedTime,
          orderFrom: "WEB"
        }
      );

      // console.log(prod, "prod");

      if (prod.status == 200 && prod.data && prod.data.url) {
        dispatch(
          rootAction.saveOrderStatus({
            url: prod.data.url,
            orderId: prod.data.orderId,
            paymentStatus: false
          })
        );

        window.location.href = prod.data.url;
      } else if (prod.status == 201 && prod.data.message == "AUTHORIZED") {
        // console.log(prod, "proding");
        dispatch(
          rootAction.orderByCoupon({
            orderId: prod.data.orderId,
            orderOtp: prod.data.orderOtp
          })
        );
      } else if (prod.data.status == 500) {
        alert("Intern serverfeil!");
      }
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};

export const checkOrderStatus = (
  ordrId,
  transaction_id,
  paymentType,
  transactionObj
) => {
  return async (dispatch) => {
    try {
      let endUrl =
        paymentType == "tdVipps"
          ? "treedrivepayment/tdorderpaymentcheckvipps"
          : "treedrivepayment/orderpaymentcheckweb";
      let response = await axios.post(
        // apiUrl + tDriveEndpoint + "treedrivepayment/orderpaymentcheckweb",
        apiUrl + tDriveEndpoint + endUrl,
        {
          transaction_id: transaction_id,
          orderId: ordrId,
          paymentType: paymentType,
          transactionObj: transactionObj
        }
      );

      if (response.data.status == 200) {
        if (
          response.data.message == "AUTHORIZED" ||
          response.data.message == "CAPTURED"
        ) {
          // dispatch(rootAction.updateOrder(response.data.data.otp));
          dispatch(rootAction.updateOrder(response.data.data));
        }
      }
    } catch (error) {
      dispatch(rootAction.updateOrder(0));

      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};

export const refundCouponValue = (ordrId) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        apiUrl + tDriveEndpoint + "treedrivepayment/refundcouponvalue",
        {
          orderId: ordrId
        }
      );

      if (response.data.status == 200) {
        dispatch(rootAction.resetOrder());
      }
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};

export const orderCreateAction = orderCreate;
