import { Layout, Tag } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { FaShoppingCart } from "react-icons/fa";
import logo from "../../assets/landscapelogo.webp";
import maplocationImg from "../../assets/mobile/maplocationImg.webp";
import ConfirmOrderModal from "../../containers/Modal/ConfirmModal";
import { rootAction } from "../../store/slices/rootSlices";
import { isMobile } from "react-device-detect";
import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

const { Header, Content } = Layout;
const HeaderLayout = (props) => {
  let address = useSelector((state) => state.locationReducer);
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  let [locationPath, setLocationPath] = useState("");
  let [prevLocation, setPrevLocation] = useState("");
  let dispatch = useDispatch();
  let [showConfirmModal, setShowConfirmModal] = useState(false);
  let location = useLocation();
  let [isRestaurant, setisRestaurant] = useState(false);
  let root = useSelector((state) => state.rootReducer);
  let user = useSelector((state) => state.userReducer);
  let vid = localStorage.getItem("vid");
  const vendorName = localStorage.getItem("vendorName");
  const resName = localStorage.getItem("resName");
  const restaurant = useSelector((state) => state.restaurantReducer.restaurant);
  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);

  const getRestaurantNameCity = () => {
    let resNameCity = "";
    if (res && Object.keys(res).length > 0) {
      resNameCity = res.name + ", " + res.address.city;
    }
    return resNameCity;
  };

  const ifTreeDriveOn = () => {
    return !location.pathname.includes("/restaurant/") ? (
      <img
        src={logo}
        alt="logo"
        className="logoSize bg-white border-radius-5"
        height={"50px"}
      />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    setLocationPath(location.pathname);

    let restaurant = location.pathname.split("/");
    restaurant = restaurant[1] == "restaurant" ? true : false;
    setisRestaurant(restaurant);
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
    }
    if (location.pathname == "/restaurants") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertCou());
    }

    if (window?.innerWidth > 720) {
      setPrevLocation("/home");
    } else if (
      location.pathname == "/profile" ||
      location.pathname == "/login" ||
      location.pathname == "/signup" ||
      location.pathname == "/vendorslist"
    ) {
      setPrevLocation("/home");
    } else if (locationPath == `/VendorsProduct/${vid}`) {
      setPrevLocation("/vendorslist");
    } else {
      setPrevLocation("/restaurants");
    }
  }, [location.pathname]);

  const onConfirmOrderModal = () => {
    setShowConfirmModal(true);
  };
  const onCancelConfrimModal = () => {
    setShowConfirmModal(false);
  };

  let getHeaderlayout = () => {
    let selOptions = [];
    let isSelected = "";

    if (res && Object.keys(res).length > 0) {

      if (
        res.isTreeDriveDriveOutAllowed == true &&
        res.isRestDriveOut == true
      ) {
        isSelected = root.orderType == "Drive Out" ? "selected" : "";
        selOptions.push({
          optValue: "Drive Out",
          label: "Utkjøring",
          isSelected
        });
      }

      if (res.isTreeDrivePickUpAllowed == true && res.isRestPickup == true) {
        isSelected = root.orderType == "Take Away" ? "selected" : "";
        selOptions.push({
          optValue: "Take Away",
          label: "Henting",
          isSelected
        });
      }
    }
    const handleSelect = (value) => {
      // console.log(`selected ${value}`);
      props.orderTypeVendor(value);
    };
    let layout = "";
    const setVendorOrderType = (e) => {
      localStorage.setItem("vendorOrderType", e);
    };
    if (
      locationPath == "/restaurants" ||
      locationPath == "/reserveTable" ||
      locationPath == "/retailerList" ||
      locationPath == "/inviteList"
    ) {
      layout = (
        <div className="search-location width-100 d-flex">
          <a
            className="top-bar locBtn"
            onClick={props.onChangeLocationHandler}
          >
            <span className="locSpan text-white d-flex align-items-center">
              <u>
                {`${address.locationAddress.substr(0, 28)}...`} &nbsp;
              </u>
              &nbsp;
              <img alt="maplocationImg" src={maplocationImg} />
            </span>
          </a>
        </div>
      );
    } else if (locationPath == "/products" || isRestaurant) {
      layout = (
        <>
          {res.availability.status === true ? (
            <div className="d-flex">
              {res.isTreeDriveDriveOutAllowed === "true" &&
              res.isTreeDrivePickUpAllowed === "true" ? (
                <select
                  className="dropSelect"
                  onChange={(e) => {
                    dispatch(rootAction.setOrderType(e.target.value));
                  }}
                >
                  <option
                    value="Take Away"
                    selected={root.orderType == "Take Away" ? "selected" : ""}
                  >
                    Henting
                  </option>
                  <option
                    value="Drive Out"
                    selected={root.orderType == "Drive Out" ? "selected" : ""}
                  >
                    Levering
                  </option>
                </select>
              ) : (
                <p className="rootOrderType mb-0">
                  <select
                    className="dropSelect border-radius-50 text-black"
                    onChange={(e) => {
                      dispatch(rootAction.setOrderType(e.target.value));
                    }}
                  >
                    {selOptions.map((e) => {
                      return (
                        <option value={e.optValue} selected={e.isSelected}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </p>
              )}
              <div
                className="cartLogo positionRelative"
                onClick={onConfirmOrderModal}
              >
                <FaShoppingCart className="cartIcon text-white" />

                <Badge bg="secondary" className="cartBadge">
                  {cartLength ? cartLength : ""}
                </Badge>
              </div>
            </div>
          ) : (
            <Tag color="#f50">
              Stengt
            </Tag>
          )}
        </>
      );
    } else if (locationPath == `/VendorsProduct/${vid}`) {
      layout = (
        <div className="d-flex">
          <p className="rootOrderType mb-0">
            <select
              className="dropSelect border-radius-50"
              onChange={(e) => {
                setVendorOrderType(e.target.value);
              }}
            >
              <option value="Take Away">Take Away</option>
              <option value="Drive Out">Drive Out</option>
            </select>
          </p>
        </div>
      );
    }

    return layout;
  };

  let getCityAndName = () => {
    let layout1 = "";
    if (locationPath == "/restaurants") {
      layout1 = <div></div>;
    }

    if (
      locationPath == "/products" ||
      locationPath == "/checkout" ||
      isRestaurant
    ) {
      layout1 = (
        <div className="d-flex">
          <h3 className="mb-0 text-white d-flex align-items-center">
            &nbsp;&nbsp;{getRestaurantNameCity()}
          </h3>
        </div>
      );
    } else if (locationPath == `/VendorsProduct/${vid}`) {
      layout1 = (
        <div className="d-flex">
          <h3 className="mb-0 text-white d-flex align-items-center">
            &nbsp;&nbsp;{vendorName}
          </h3>
        </div>
      );
    }
    return layout1;
  };
  return (
    <Header className="other-nav mainBgColor width-100 positionFixed zIndex-99">
      <div className="navWrapper d-flex">
        {!isMobile && (
          <div className="d-flex align-items-center">
            <NavLink to="/home" className="nav-logo">
              {ifTreeDriveOn()}
            </NavLink>
            {getCityAndName()}
          </div>
        )}
        {isMobile && (
          <>
            {window.location.pathname == "/checkout" ? (
              <>
                <NavLink
                  to={
                    window.location.pathname == "/checkout"
                      ? "/products"
                      : prevLocation
                  }
                  className="nav-logo col-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                  >
                    <path d="M16.25 22.6 5.625 11.975 16.25 1.35l2.2 2.2-8.425 8.425L18.45 20.4Z" />
                  </svg>
                </NavLink>
                {resName}
              </>
            ) : (
              <></>
            )}
            {window.location.pathname == "/products" ? (
              <h3 className="textWhite font-22 mb-0 font-700">{resName}</h3>
            ) : (
              <></>
            )}
          </>
        )}

        {getHeaderlayout()}
      </div>
      {showConfirmModal && cartLength > 0 && (
        <ConfirmOrderModal
          onCancelConfrimModal={onCancelConfrimModal}
        ></ConfirmOrderModal>
      )}
    </Header>
  );
};
export default HeaderLayout;
