import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import friends from "../../assets/friends.webp";
import { Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";

import BottomFooter from "../Footer/BottomFooter";
export default function ShareReferral() {
  let navigate = useNavigate("");
  const userReferralCode = localStorage.getItem("referralCode") || "******";
  let user = useSelector((state) => state.userReducer);

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(userReferralCode);
    } catch (error) {
      console.error("Failed to copy referral code:", error);
    }
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Referral Code",
          text: `Her er min henvisningskode: ${userReferralCode}`,
          url: window.location.href 
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  const content = () => {
    return (
      <Row className="p-3 justify-content-center">
        <Col span={24}>
          <h3 className="font-24 font-700 mt-3">Inviter venner til EzyGo!</h3>
          <p>
            Inviter vennene dine til EzyGo og få mynter kreditert etter å ha
            lagt inn sin første bestilling. Og la dem tjene mynter på SignUp.
          </p>
        </Col>
        <Col
          sm={24}
          lg={8}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={friends}
            loading="lazy"
            alt="Invite Friends"
            width={"100%"}
          />
        </Col>
        <Col span={24}>
          <Row
            style={{ background: "#0066CC" }}
            className="border-radius-10 p-3 text-white justify-content-between align-items-center"
          >
            <Col>
              <h3 className="font-24 text-white mb-1">{userReferralCode}</h3>
              <p className="mb-0">Kopier henvisningskoden ovenfor.</p>
            </Col>
            <Col>
              <Button
                type="text"
                shape="circle"
                icon={
                  <ShareAltOutlined
                    style={{ color: "#fff", fontSize: "60px" }}
                  />
                }
                onClick={handleShare}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mt-3">
          <p>
            <b>
              <i>Jo mer du henviser, jo mer tjener du!</i>
            </b>
          </p>
          <p>
            Jo mer du henviser, jo mer tjener du!
            <br /> Del det og del moroa.
          </p>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="commonShadowAndRound my-5 bg-white">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
