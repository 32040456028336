import { Row, Col, Button } from "antd";
import { cartAction } from "../../store/slices/cartslices";
import { useDispatch, useSelector } from "react-redux";

const OrderedProducts = (props) => {
  let dispatch = useDispatch();

  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  let root = useSelector((state) => state.rootReducer);

  const onRemoveProduct = (id, index) => {
    dispatch(
      cartAction.removeProductFromCart({
        id: id,
        index: index,
        restaurant: restaurant,
        orderType: root.orderType
      })
    );
  };
  const onRemoveExtraItems = (productId, productIndex, extraId, extraIndex) => {
    dispatch(
      cartAction.removeExtraItemFromCart({
        productId,
        productIndex,
        extraId,
        extraIndex
      })
    );
  };
  return (
    <div id="product-list">
      {props.cart.length &&
        props.cart.map((sinCart, index) => {
          let newPrice = sinCart.quantity * sinCart.incPrice;
          return (
            <div className="added-product mb-4" key={`${sinCart._id}+${index}`}>
              <Row className="justify-content-between">
                <Col span={10}>
                  {" "}
                  <p className="font-700 font-18 mb-0">{sinCart.name}</p>
                </Col>
                <Col span={6}>
                  <Row
                    className="increaseBtnBOrders"
                    justify="center"
                    align="middle"
                  >
                    <Col span={6}>
                      <Button
                        type="text"
                        onClick={() => onRemoveProduct(sinCart._id, index)}
                      >
                        -
                      </Button>
                    </Col>
                    <Col span={12}>{sinCart.quantity}</Col>
                  </Row>{" "}
                </Col>
                <Col span={6} className="text-right">
                  <p className="itemName mb-0">Kr. {newPrice.toFixed(2)}</p>
                </Col>
                {sinCart.instruction == "" ? (
                  ""
                ) : (
                  <Col
                    span={24}
                    className="d-flex flex-column justify-content-start"
                  >
                    <p className="itemName">Bruksanvisning:</p>
                    <p className="itemName fst-italic intr-p billText">
                      {sinCart.instruction.join(", ")}
                    </p>
                  </Col>
                )}
              </Row>
            </div>
          );
        })}
    </div>
  );
};
export default OrderedProducts;
