import React from "react";
import BottomFooter from "../Footer/BottomFooter";
import appleqr from "../../assets/qr/appstore.webp";
import googleqr from "../../assets/qr/playstore.webp";
import playStore from "../../assets/googlePLayBlack.webp";
import appStore from "../../assets/appStoreBlack.webp";
import downloadApp from "../../assets/downlordappgirl.webp";
import { Row, Col } from "antd";
import { useNavigate } from "react-router";

export default function UnderConstruction() {
  return (
    <div>
      <Row className="underConstructionImg mt-5 pt-4">
        <Col span={8} className="d-flex flex-column">
          <p className="font-55 font-700 mb-2" style={{ lineHeight: "65px" }}>
            Last ned appen for flere fordeler!
          </p>
          <div className="d-flex">
            <div className="d-flex flex-column border-radius-10 p-2">
              <a
                href="https://apps.apple.com/in/app/tree-drive/id6444897318"
                target="_blank"
              >
                <img alt="appStore" src={appStore} style={{ width: "100%" }} />
              </a>
              <img
                alt="appleqr"
                className="mt-3 border-radius-10"
                src={appleqr}
                style={{ width: "100%", border: "1px solid" }}
              />
            </div>
            <div className="d-flex flex-column border-radius-10 p-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser"
                target="_blank"
              >
                <img
                  alt="playStore"
                  src={playStore}
                  style={{ width: "100%" }}
                />
              </a>
              <img
                alt="googleqr"
                className="mt-3 border-radius-10"
                src={googleqr}
                style={{ width: "100%", border: "1px solid" }}
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <img src={downloadApp} style={{ width: "100%" }} loading="lazy" />
        </Col>
      </Row>
      <BottomFooter />
    </div>
  );
}
