import { LOCATION_ACTION } from "./index";
import axios from "axios";
import {
  getAddressFromlatLong,
  getCurrentLocationLatLng
} from "../../helper/location";
import { locationAction } from "../../store/slices/locationslices";
import { restaurantAction } from "../../store/slices/restaurantslices";

import { apiUrl } from "../../helper/endpoint";
import { errorAction } from "../slices/errorSlices";
export const sendLocationAction = () => {
  return async (dispatch) => {
    try {
      let latLng = await getCurrentLocationLatLng();
      // console.log(latLng,'Line no 16');
      let address = await getAddressFromlatLong(latLng.lat, latLng.lng);
      if (address && latLng.lat && latLng.lng) {
        dispatch(locationAction.saveAddress(address));
      }
    } catch (error) {
      if ((error = "User denied Geolocation")) {
        error = "Please allow location Permission";
      }

      dispatch(
        errorAction.saveError({
          errorMessage: error,
          isError: true
        })
      );
    }
  };
};

export const getRestaurants = (location, orderType) => {
  return async (dispatch) => {
    let urlRes =
      orderType == "Drive Out"
        ? "restaurantdistance"
        : "restaurantdistancetakeaway";

    let formData = {};

    formData.latitude = location.lat;
    formData.longitude = location.lng;

    if (orderType == "reserveTable") {
      formData.reserveTablePermissionKey = true;
    }

    try {
      let response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/restaurant/${urlRes}`,
        formData
      );

      dispatch(restaurantAction.saveRestuarant(response.data.data.restaurants));
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message,
          isError: true
        })
      );
    }
  };
};

export const saveLocationAndLatlng = (address, lat, lng, locDetails) => {
  return (dispatch) => {
    try {
      dispatch(
        locationAction.saveAddress({
          address,
          lat,
          lng,
          locDetails
        })
      );
    } catch (error) {
      dispatch(
        errorAction.saveError({
          errorMessage: error.message ? error.message : error,
          isError: true
        })
      );
    }
  };
};
