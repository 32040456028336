import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { isMobile } from "react-device-detect";

import axios from "axios";

import { Col, Row, Divider } from "antd";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import circleLogin from "../../assets/circleLogin.png";
import pinLogin from "../../assets/pinLogin.png";

import Alert from "react-bootstrap/Alert";

import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Signup() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  let [refCode, setRefCode] = useState("");
  useEffect(() => {
    let refCode = searchParams.get("ref");
    if (refCode) {
      setRefCode(refCode);
    }
  }, []);

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let firstName = e.target.inputFirstName.value;
    let lastName = e.target.inputLastName.value;
    let email = e.target.inputEmail.value;
    let phone = e.target.inputPhone.value;
    let referral = e.target.inputReferral.value;
    let password = e.target.inputPassword.value;
    let conPassword = e.target.inputConPassword.value;
    let countryCode = e.target.inputCountryCode.value;

    let inputFields = [firstName, email, phone, password, conPassword];

    if (!validateForm(inputFields)) {
      // console.log(firstName, lastName, email, phone, password, referral);
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Vennligst fyll ut alle de obligatoriske feltene!");
    } else {
      if (phone.length > 10 || phone.length < 8) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Vennligst skriv inn et gyldig telefonnummer!");
        return;
      }

      if (password != conPassword) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Vennligst bekreft passordet ditt!");
        return;
      }

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        countryCode: countryCode,
        password: password,
        referral: referral,
        isAgreed: true
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/appuser`, formData)
        .then((response) => {
          if (response.data.status == 200) {
            setShowAlert(true);
            setAlertType("success");
            setAlertMsg(response.data.message);
            window.location = "verify";
          } else {
            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(error.response.data.message);
        });
    }
  };

  const navigate = useNavigate("");
  const commonCardSignUp = () => {
    return (
      <>
        <Row
          className="d-flex flex-column align-items-center justify-content-center logBox"
          style={{ borderRadius: "20px" }}
        >
          <Col span={24}>
            <h4 class="font-18 font-700 text-center">Opprett din profil</h4>
            <h4
              class="font-18 font-700 mt-1 d-flex align-items-center justify-content-center"
              style={{ color: "#0066CC " }}
            >
              <img src={circleLogin} loading="lazy" />
              &nbsp; Forenklet for deg!&nbsp;
              <img src={pinLogin} loading="lazy" />
            </h4>
            <Divider>Registrer deg</Divider>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="row mt-3" controlId="inputFirstName">
                <Col span={12}>
                  <FloatingLabel controlId="floatingInputGrid" label="Fornavn*">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Fornavn"
                      name="inputFirstName"
                    />
                  </FloatingLabel>
                </Col>

                <Col span={12}>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Etternavn*"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Etternavn"
                      name="inputLastName"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

              <Form.Group controlId="formBasicEmail" className=" my-3">
                <Col span={24}>
                  <FloatingLabel controlId="floatingInputGrid" label="E-post*">
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@example.com"
                      name="inputEmail"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

              <Form.Group className="row" controlId="formBasicEmail">
                <Col span={24} className="d-flex">
                  <Form.Select
                    style={{ width: "25%" }}
                    aria-label="Velg landskode"
                    name="inputCountryCode"
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>
                  <FloatingLabel
                    style={{ width: "100%" }}
                    controlId="floatingInputGrid"
                    label="Telefonnummer*"
                  >
                    <Form.Control
                      required
                      type="number"
                      placeholder="Telefonnummer"
                      name="inputPhone"
                    />
                  </FloatingLabel>
                </Col>
                <Col span={24} className="mt-3">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Henvisningskode (hvis noen)"
                  >
                    <Form.Control
                      defaultValue={refCode}
                      type="text"
                      placeholder="Referral Code"
                      name="inputReferral"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

              <Form.Group className="row my-3" controlId="formBasicPassword">
                <Col span={12}>
                  <FloatingLabel controlId="floatingInputGrid" label="Passord*">
                    <Form.Control
                      required
                      type="password"
                      placeholder=""
                      name="inputPassword"
                    />
                  </FloatingLabel>
                </Col>

                <Col span={12}>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Bekreft passord*"
                  >
                    <Form.Control
                      required
                      type="password"
                      placeholder=""
                      name="inputConPassword"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

              <p>
                Ved å klikke på "Opprett profil"-knappen godtar du EzyGo's&nbsp;
                <i>
                  {" "}
                  <NavLink to="/terms" className="col-1">
                    Vilkår og betingelser
                  </NavLink>
                </i>
              </p>

              {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}
              <Button
                variant="primary"
                type="submit"
                className="width-100 py-2 border-radius-10"
              >
                Skape
              </Button>
              <br />
              <p className="py-2">
                Eksisterende bruker, vennligst&nbsp;
                <NavLink to="/login" className=" col-1">
                  Logg Inn
                </NavLink>
              </p>
            </Form>
          </Col>
          <Col span={24}>
            <NavLink to="/home" style={{ color: "#0066CC" }}>
              {" "}
              <u>Fortsett som gjestebruker!</u>
            </NavLink>{" "}
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    if (user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages p-5">
          <div className="d-flex justify-content-center align-items-center py-5 mt-5 signup">
            {commonCardSignUp()}
          </div>
        </section>
      )}
      {isMobile && (
        <section
          className="signUpBg p-2 d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          {commonCardSignUp()}
        </section>
      )}

      {/* <BottomFooter /> */}
    </>
  );
}
