import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router";
import { useEffect } from "react";
import { sendLocationAction } from "./store/actions/locationaction";
import "./App.css";
// routes
import EzygoIndex from "./components/Ezygo/EzygoIndex";
import OnlineOrderNew from "./components/OnlineOrderPage/OnlineOrderNew";

import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import Cancellation from "./components/Privacy/Cancellation";
import ScamAlert from "./components/Privacy/ScamAlert";
import Disclaimer from "./components/Privacy/Disclaimer";

import TopBar from "./components/Header/TopBar";
import ReserveTable from "./components/Restaurants/ReserveTable";
import RestaurantListComponent from "./components/Restaurants/ResturantListComponent";
import DriveOutCheckout from "./components/Checkout/DriveoutCheckout";
import { OrderSummary } from "./components/RestaurantProducts/OrderSummary";
import { Product } from "./components/RestaurantProducts/Products/Product";

import DigitalServices from "./components/WebServices/DigitalServices";

import SalonsList from "./components/Salons/SalonList";
// /* don't delete below data */
// import Salons from "./components/Salons/Salons";
// import SalonOrder from "./components/Salons/Salons";

import TflMarket from "./components/TFLMarket/TflMarket";
import TflMarketCart from "./components/TFLMarket/TflMarketCart";

// import VendorsList from "./components/Vendor/VendorsList";
// import VendorsProduct from "./components/Vendor/VendorsProduct";
// import VendorsCheckout from "./components/Vendor/VendorCheckout";

import EmpTy from "./components/EmptyPage/EmpTy";

import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import Reset from "./components/Reset/Reset";
import Verify from "./components/Verify/Verify";
import OrderHistory from "./components/Profile/OrderHistory";

import TflGiftCardOthers from "./components/TFL Gift Card/TflGiftCardOthers";
import TflGiftCardMyself from "./components/TFL Gift Card/TflGiftCardMyself";
import TFLOrderSummary from "./components/TFLMarket/TFLOrderSummary";
import TFLCoinExchange from "./components/TFL Gift Card/TFLCoinExchange";
import ShowTflGiftCard from "./components/TFL Gift Card/ShowTFLGiftCard";
import TFLGiftCardPage from "./components/TFL Gift Card/TFLGiftCardPage";

import SuceessCouponPurchase from "./components/Coupon/BuyCoupon/SuceessCouponPurchase";
import SuccessCouponOrderSummary from "./components/RestaurantProducts/SuccessCoupon";
import ShareReferral from "./components/Profile/ShareReferral";
import Modal1Slide from "./components/OnlineOrderPage/Modal1Slide";
import ShortCutToAll from "./components/Profile/ShortCutToAll";
import ABoard from "./components/ABoard/ABoard";
import ProfileInABoard from "./components/ABoard/ProfileInABoard";
import EditProfile from "./components/ABoard/EditProfile";
import ChangePass from "./components/ABoard/ChangePass";
import ToInviteList from "./components/ToInvitePages/ToInviteList";
import AgentProfile from "./components/ABoard/AgentProfile";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";
import Contact from "./components/OnlineOrderPage/Contact";
import EzygoPrivacy from "./components/AppPrivacy/EzygoPrivacy";
import EzygoTerms from "./components/AppPrivacy/EzygoTerms";

// /* don't delete below data */

// import RetailerList from "./components/Retailer/RetailerList";
// import RetailerProduct from "./components/Retailer/RetailerProduct";
// import RetailerCheckout from "./components/Retailer/RetailerCheckout";
// import RetailerPayStatus from "./components/Retailer/ReatilerPayStatus";
function App() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(sendLocationAction());
  }, []);

  return (
    <Routes>
      <Route path="/" element={<EzygoIndex />}>
        <Route path="/home" element={<OnlineOrderNew />} />
        <Route path="/tflGiftCard" element={<TFLGiftCardPage />} />
        <Route path="/showTflGiftCard" element={<ShowTflGiftCard />} />
        <Route path="/getTflCouponforothers" element={<TflGiftCardOthers />} />
        <Route path="/getTflCouponforme" element={<TflGiftCardMyself />} />
        <Route path="/shortCutToAll" element={<ShortCutToAll />} />
        <Route path="/a-board" element={<ABoard />} />
        <Route path="/profileInAboard" element={<ProfileInABoard />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/changePassword" element={<ChangePass />} />
        <Route path="/tflMarketcart" element={<TflMarketCart />} />
        <Route path="/tflCoinExchange" element={<TFLCoinExchange />} />
        <Route path="/modal/:modalId" element={<Modal1Slide />} />
        <Route path="/salonsList" element={<SalonsList />} />
        <Route path="/agentProfile" element={<AgentProfile />} />
        <Route path="/underConstruction" element={<UnderConstruction />} />{" "}
        <Route path="/contact" element={<Contact />} />{" "}
        <Route
          path="/orderstatuscoupon"
          element={<SuccessCouponOrderSummary />}
        />
        <Route exact path="/orderHistory" element={<OrderHistory />} />
        <Route exact path="/shareReferral" element={<ShareReferral />} />
      </Route>

      <Route path="" element={<TopBar />}>
        <Route path="/reserveTable" element={<ReserveTable />} />
        <Route path="/restaurants" element={<RestaurantListComponent />} />
        <Route path="/inviteList" element={<ToInviteList />} />
        <Route path="/tflMarket" element={<TflMarket />} />
        <Route path="/TFLOrderSummary" element={<TFLOrderSummary />} />
        <Route path="/digitalServices" element={<DigitalServices />} />
        <Route path="/products" element={<Product />} />
        <Route path="/checkout" element={<DriveOutCheckout />} />
        <Route path="/orderstatus" element={<OrderSummary />} />
        {/* <Route path="/VendorsList" element={<VendorsList />} />
        <Route path="/VendorsProduct/:id" element={<VendorsProduct />} />
        <Route path="/VendorsCheckout" element={<VendorsCheckout />} /> */}
        <Route exact path="/restaurant/:slug" element={<Product />} />
        {/* don't delete below data */}
        {/* <Route path="/salons" element={<Salons />} /> */}
        {/* <Route path="/salonOrder" element={<SalonOrder />} /> */}
        {/* <Route path="/RetailerList" element={<RetailerList />} />{" "}
        <Route path="/RetailerProduct/:id" element={<RetailerProduct />} />
        <Route path="/RetailerCheckout" element={<RetailerCheckout />} />
        <Route path="/RetailerPayStatus" element={<RetailerPayStatus />} /> */}
      </Route>

      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/terms" element={<Terms />} />

      <Route path="/RestaurantList/ordersummary" element={<OrderSummary />} />

      <Route exact path="/salesAndCancellation" element={<Cancellation />} />
      <Route exact path="/scamAlert" element={<ScamAlert />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />

      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/verify" element={<Verify />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/reset" element={<Reset />} />
      <Route exact path="/ezygoprivacy" element={<EzygoPrivacy />} />
      <Route exact path="/ezygoterms" element={<EzygoTerms />} />
      <Route path="/couponpaymentstatus" element={<SuceessCouponPurchase />} />

      <Route exact path="/EmptyPage" element={<EmpTy />} />
    </Routes>
  );
}

export default App;
