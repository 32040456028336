import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import offerIcon from "../../../../assets/offerTag.webp";

const SuperCategoryComponent = (props) => {
  const checkOffer = (prod) => {
    let showOffer = false;
    let todayDate = new Date();

    for (let i = 0; i < prod.length; i++) {
      let thisItem = prod[i];

      if (thisItem.offerEnable) {
        let startDate = new Date(thisItem.offer.startDate);
        let endDate = new Date(thisItem.offer.endDate);

        if (todayDate >= startDate && todayDate <= endDate) {
          showOffer = true;
          break;
        }
      }
    }
    return showOffer;
  };

  return (
    <ListGroup as="ul">
      {props.subCategory.map((sinRes, index) => {
        let showOffer = false;
        if (checkOffer(sinRes.products)) {
          showOffer = true;
        }

        return (
          <ListGroup.Item
            className="d-flex justify-content-between align-items-center"
            as="li"
            key={sinRes._id}
            onClick={() => {
              props.onClickSuperCategoryHandler(sinRes);
            }}
          >
            <p className="mb-0">{sinRes.name}</p>

            {showOffer && (
              <img
                src={offerIcon}
                loading="lazy"
                alt="offer available"
                width={70}
              />
            )}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};
export default SuperCategoryComponent;
