let getProductsIngridents = (sinIng, singleProduct) => {
  
  let newIng = [];
  let ingridents = sinIng.filter((sinAlling) => {
    singleProduct.ingredents.map((e)=>{
      if(e.stockproduct == sinAlling._id){
        let thisIng = {
          ...sinAlling,
          ingId: e._id
        };
        newIng.push(thisIng);
      }
    })

  });

  return newIng;
};

let getIncreasedPrice = (singleProduct, incPricePer) => {
  let actPrice = +singleProduct.price;

  let incPricePers = incPricePer;
  return actPrice + (incPricePers / 100) * actPrice;
};
export const getProductsIngrident = getProductsIngridents;
export const getIncreasedPrices = getIncreasedPrice;
