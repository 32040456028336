import React from "react";
import BottomFooter from "../Footer/BottomFooter";

export default function AgentProfile() {
  return (
    <>
      <div className="agentPage"></div>
      <BottomFooter />
    </>
  );
}
