import React from "react";
import { Row, Col } from "antd";
import { useNavigate } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import icon1 from "../../assets/mobile/ezygo.webp";
import icon2 from "../../assets/mobile/history.webp";
import icon4 from "../../assets/mobile/gift_card.webp";
import icon5 from "../../assets/mobile/share.webp";
import ProfileIcon from "../../assets/mobile/A-Board.webp";

import "aos/dist/aos.css";

export default function MobileNavBarBottom(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  return (
    <Row className="position-fixed mobileNavBottom bottom-0 width-100 p-1 justify-content-between">
      <Col span={4} className="text-center">
        <a
          onClick={() => {
            navigate("/home");
          }}
          className="d-flex flex-column align-items-center"
        >
          <img alt="EzyGo" src={icon1} />
          EzyGo
        </a>
      </Col>
      <Col span={5} className="text-center">
        <a
          onClick={() => {
            navigate("/tflgiftcard");
          }}
          className="d-flex flex-column align-items-center"
        >
          <img src={icon4} loading="lazy" />
          Agentkort
        </a>
      </Col>{" "}
      <Col span={4} className="text-center">
        <a
          onClick={() => {
            navigate("/orderHistory");
          }}
          className="d-flex flex-column align-items-center"
        >
          <img alt="TFL Coins" src={icon2} loading="lazy" />
          Historie
        </a>
      </Col>{" "}
      <Col span={4} className="text-center">
        <a
          onClick={() => {
            navigate("/a-board");
          }}
          className="d-flex flex-column align-items-center"
        >
          <img alt="Profil" src={ProfileIcon} loading="lazy" />
          Arkiv
        </a>
      </Col>{" "}
      <Col span={4} className="text-center">
        <a
          onClick={() => {
            navigate("/shareReferral");
          }}
          className="d-flex flex-column align-items-center"
        >
          <img src={icon5} loading="lazy" />
          Dele
        </a>
      </Col>{" "}
    </Row>
  );
}
