import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { rootAction } from "../../store/slices/rootSlices";
import {
  Row,
  Col,
  Card,
  Tag,
  Table,
  Descriptions,
  Skeleton,
  Divider
} from "antd";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";

export default function OrderHistory() {
  let dispatch = useDispatch();
  let location = useLocation();
  let [isLoading, setStateLoading] = useState(true);
  let navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  const [show, setShow] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [modalVar, setModalVar] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setModalVar(data);
    // console.log("modalvar", modalVar);
  };

  const columns = [
    {
      title: "Antall",
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: "Gjenstandsnavn",
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: "Pris",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.price * item.quantity).toFixed(2);
      }
    }
  ];

  useEffect(() => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    const fetchData = async () => {
      setStateLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/appuserallorders`,
          cardHistoryData
        );
        setResponseData(response.data.data.data);
        setHistoryCount(response.data.data.totalOrders);
      } catch (error) {
        console.error(
          "---error user---",
          error.response?.data?.message || error.message
        );
      } finally {
        setStateLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isMobile && (
        <Row className="vh90andScroll">
          {isLoading ? (
            <div className="skeltonTopMargin width-100 px-3">
              <Row>
                <Col xs={24} sm={24} lg={8} className="width-100">
                  <Skeleton avatar paragraph={{ rows: 5 }} />
                </Col>
                <Col xs={24} sm={24} lg={8} className="width-100">
                  <Skeleton avatar paragraph={{ rows: 5 }} />
                </Col>
              </Row>
            </div>
          ) : (
            <>
              {!historyCount ? (
                <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                  <p className="text-dark font-18 font-700">
                    Du har ikke bestilt ennå!
                  </p>
                  <a
                    className="aboutNavLink greenAccentBg border-radius-10 font-900"
                    style={{
                      cursor: "pointer",
                      padding: "8px 50px"
                    }}
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                  >
                    BESTILL
                  </a>
                </div>
              ) : (
                <Row gutter={[16, 16]} className="mb-5 px-3">
                  <h3 class="font-24 font-700 mb-0">Bestillingshistorikk</h3>
                  {responseData.map((data) => (
                    <Col xs={24} sm={12} lg={8} key={data._id}>
                      <Card
                        className="historyCard border-radius-10"
                        actions={[
                          <Button
                            variant="primary"
                            onClick={() => handleShow(data)}
                            style={{
                              width: "100%",
                              borderRadius: "0 0 10px 10px"
                            }}
                          >
                            Se detaljer
                          </Button>
                        ]}
                      >
                        <Row
                          id="cardHistory"
                          className="justify-content-between align-items-center"
                        >
                          <Col span={24}>
                            <h4>
                              {data?.branchId?.name},&nbsp;
                              {data?.branchId?.address?.city}
                            </h4>
                            <Divider />{" "}
                            <p className="mb-0">
                              Ordretype: <span>{data?.orderType}</span>
                            </p>
                            <Divider />{" "}
                            <Row className="justify-content-between align-items-center">
                              <p className="mb-0">
                                <b>OTP:</b> {data?.otp}
                              </p>
                              <Tag color="#f50">{data?.orderStatus}</Tag>
                            </Row>
                            <Divider />
                            <p>{new Date(data?.createdAt).toLocaleString()}</p>
                            {data?.orderStatus === "IN-PROCESS" &&
                            data?.orderType === "Drive Out" ? (
                              <>
                                <p>
                                  Førerens navn:
                                  <span>
                                    {data?.driverId?.firstName}{" "}
                                    {data?.driverId?.lastName}
                                  </span>
                                </p>
                                <p>
                                  Sjåførens kontaktnummer:
                                  <span>{data?.driverId?.phoneNumber}</span>
                                </p>
                              </>
                            ) : null}
                            <Divider />
                            <Row className="justify-content-between align-items-center">
                              <p className="mb-0">
                                Totalt: <span>Kr. {data?.totalPrice}</span>
                              </p>
                              <p className="mb-0">
                                Quantity:{" "}
                                <span>{data?.items[0]?.quantity}</span>
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="modalHistory"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {modalVar?.branchId?.name} &nbsp;
                        <Tag color="#f50">{modalVar?.orderStatus}</Tag>
                        <br />
                        <i>OTP: {modalVar?.otp}</i>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table columns={columns} dataSource={modalVar.items} />
                      <Descriptions bordered>
                        {!modalVar?.items?.totalExtraPrice && (
                          <Descriptions.Item label="Total Merpris">
                            Kr. {modalVar?.items?.totalExtraPrice ?? 0}
                          </Descriptions.Item>
                        )}
                        {modalVar?.tdServiceCharge == 0 ? (
                          <></>
                        ) : (
                          <Descriptions.Item label="Tjenesteskatt">
                            Kr. {modalVar?.tdServiceCharge}
                          </Descriptions.Item>
                        )}
                        {modalVar?.discount != 0 && (
                          <Descriptions.Item label="Rabatt">
                            Kr. {modalVar?.discount}
                          </Descriptions.Item>
                        )}

                        {modalVar?.tdDriveOutCharge == 0 ? (
                          <></>
                        ) : (
                          <Descriptions.Item label="Utkjøringskostnader">
                            Kr. {modalVar?.tdDriveOutCharge}
                          </Descriptions.Item>
                        )}

                        <Descriptions.Item label="Totalt beløp">
                          Kr. {modalVar?.taxPrice}
                        </Descriptions.Item>
                      </Descriptions>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={handleClose}
                        style={{ width: "100%" }}
                      >
                        Greit
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Row>
              )}
            </>
          )}
        </Row>
      )}
      {!isMobile && (
        <Row style={{ marginTop: "64px" }} className="px-5 pt-3">
          {isLoading ? (
            <div className="skeltonTopMargin width-100 px-3">
              <Row>
                <Col xs={24} sm={24} lg={8} className="width-100">
                  <Skeleton avatar paragraph={{ rows: 5 }} />
                </Col>
                <Col xs={24} sm={24} lg={8} className="width-100">
                  <Skeleton avatar paragraph={{ rows: 5 }} />
                </Col>
              </Row>
            </div>
          ) : (
            <>
              {!historyCount ? (
                <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                  <p className="text-dark font-18 font-700">
                    Du har ikke bestilt ennå!
                  </p>
                  <a
                    className="aboutNavLink greenAccentBg border-radius-10 font-900"
                    style={{
                      cursor: "pointer",
                      padding: "8px 50px"
                    }}
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                  >
                    BESTILL
                  </a>
                </div>
              ) : (
                <div className="d-flex flex-column width-100">
                  {" "}
                  <h3 class="font-24 font-700 mb-0 px-4">
                    Bestillingshistorikk
                  </h3>
                  <br />
                  <Row gutter={[16, 16]} className="mb-5 px-3">
                    {responseData.map((data) => (
                      <Col lg={8} key={data._id}>
                        <Card
                          className="historyCard border-radius-10"
                          actions={[
                            <Button
                              variant="primary"
                              onClick={() => handleShow(data)}
                              style={{
                                width: "100%",
                                borderRadius: "0 0 10px 10px"
                              }}
                            >
                              Se detaljer
                            </Button>
                          ]}
                        >
                          <Row
                            id="cardHistory"
                            className="justify-content-between align-items-center"
                          >
                            {/* <Col span={8} className="center-align">
                              <Avatar
                                src={`${s3url}/${data?.branchId?.logo}`}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Col> */}
                            <Col span={24}>
                              <h4>
                                {data?.branchId?.name},&nbsp;
                                {data?.branchId?.address?.city}
                              </h4>
                              <Divider />{" "}
                              <p className="mb-0">
                                Ordretype: <span>{data?.orderType}</span>
                              </p>
                              <Divider />{" "}
                              <Row className="justify-content-between align-items-center">
                                <p className="mb-0">
                                  <b>OTP:</b> {data?.otp}
                                </p>
                                <Tag color="#f50">{data?.orderStatus}</Tag>
                              </Row>
                              <Divider />
                              <p>
                                {new Date(data?.createdAt).toLocaleString()}
                              </p>
                              {data?.orderStatus === "IN-PROCESS" &&
                              data?.orderType === "Drive Out" ? (
                                <>
                                  <p>
                                    Førerens navn:
                                    <span>
                                      {data?.driverId?.firstName}{" "}
                                      {data?.driverId?.lastName}
                                    </span>
                                  </p>
                                  <p>
                                    Sjåførens kontaktnummer:
                                    <span>{data?.driverId?.phoneNumber}</span>
                                  </p>
                                </>
                              ) : null}
                              <Divider />
                              <Row className="justify-content-between align-items-center">
                                <p className="mb-0">
                                  Totalt: <span>Kr. {data?.totalPrice}</span>
                                </p>
                                <p className="mb-0">
                                  Quantity:{" "}
                                  <span>{data?.items[0]?.quantity}</span>
                                </p>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="modalHistory"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {modalVar?.branchId?.name} &nbsp;
                          <Tag color="#f50">{modalVar?.orderStatus}</Tag>
                          <br />
                          <i>OTP: {modalVar?.otp}</i>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table
                          columns={columns}
                          layout="vertical"
                          dataSource={modalVar.items}
                        />
                        <Descriptions bordered layout="vertical">
                          {/* <Descriptions.Item label="Total produktpris">
                            Kr. {modalVar?.totalPrice ?? 0} 
                          </Descriptions.Item>*/}
                          {!modalVar?.items?.totalExtraPrice && (
                            <Descriptions.Item label="Total Merpris">
                              Kr. {modalVar?.items?.totalExtraPrice ?? 0}
                            </Descriptions.Item>
                          )}
                          {modalVar?.tdServiceCharge == 0 ? (
                            <></>
                          ) : (
                            <Descriptions.Item label="Tjenesteskatt">
                              Kr. {modalVar?.tdServiceCharge}
                            </Descriptions.Item>
                          )}
                          {modalVar?.discount != 0 && (
                            <Descriptions.Item label="Rabatt">
                              Kr. {modalVar?.discount}
                            </Descriptions.Item>
                          )}

                          {modalVar?.tdDriveOutCharge == 0 ? (
                            <></>
                          ) : (
                            <Descriptions.Item label="Utkjøringskostnader">
                              Kr. {modalVar?.tdDriveOutCharge}
                            </Descriptions.Item>
                          )}
                          <Descriptions.Item label="Totalt beløp">
                            Kr. {modalVar?.taxPrice ?? 0}
                          </Descriptions.Item>
                        </Descriptions>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={handleClose}
                          style={{ width: "100%" }}
                        >
                          Greit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                </div>
              )}
            </>
          )}
        </Row>
      )}
      <BottomFooter />
    </>
  );
}
