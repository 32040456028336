import OrderedProducts from "./OrderProducts";
import TotalTaxCal from "./TotalTaxCal";
import CartOrderButton from "./CartOrderButton";
import { useSelector } from "react-redux";

const ConfirmOrder = (props) => {
  let cart = useSelector((state) => state.cartReducer);

  return (
    <>
      <div className="bill-body">
        <div className="billModalProductList p-3 border-radius-10 spreadedShadow">
          <OrderedProducts
            cart={cart.cartItems && cart.cartItems.length ? cart.cartItems : []}
          />
        </div>
        <TotalTaxCal cart={cart} />
      </div>
      <CartOrderButton handleCancel={props.handleCancel} />
    </>
  );
};
export default ConfirmOrder;
