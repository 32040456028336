import openSocket from "socket.io-client";
import { apiUrl } from "./endpoint";
export const socket = openSocket.connect(`${apiUrl}`, {
  reconnect: true
});

socket.on("connect_error", (err) => {
  // console.log(`connect_error due to ${err.message}`);
});

// console.log("socket---------", socket);
