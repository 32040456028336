import React, { useEffect, useState } from "react";
import MainDetail from "./Forms/FormMainDetail";
import MapForm from "./Forms/MapForm";
import { Card, Row, Col, Tag, Form } from "antd";
import Footer from "./Footer/footer";
import SideCart from "./SideCart/SideCart";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveCustomer } from "../../store/actions/customeraction";
import BottomFooter from "../Footer/BottomFooter";
import MobileNavBarBottom from "../Footer/MobileNavBarBottom";
import { isMobile } from "react-device-detect";

const DriveOutCheckout = () => {
  let [isformValid, setIsFormValid] = useState(false);
  let [enablePay, setEnablePay] = useState(false);
  let [formSet, setFormSet] = useState(false);
  let [userData, setUserData] = useState({});

  let selfirstName = "";
  let sellastName = "";
  let selphoneNo = "";
  let selemail = "";
  let selcountryCode = "+47";
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  const dispatch = useDispatch();
  let location = useSelector((state) => state.locationReducer.locationAddress);
  let root = useSelector((state) => state.rootReducer);
  let navigate = useNavigate();

  const onAddCustomer = () => {
    dispatch(
      saveCustomer({
        email: userData.email,
        phone: userData.phoneNo,
        lastName: userData.lastName,
        firstName: userData.firstName,
        countryCode: userData.countryCode,
        locAddress: location
      })
    )
      .then(setEnablePay(true))
      .catch(setFormSet(true));
  };
  const setFormValid = (
    status,
    lastName,
    firstName,
    email,
    phoneNo,
    countryCode
  ) => {
    setUserData({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNo: phoneNo,
      countryCode: countryCode
    });

    selfirstName = firstName;
    sellastName = lastName;
    selemail = email;
    selphoneNo = phoneNo;
    selcountryCode = countryCode;
    setIsFormValid(status);
  };

  useEffect(() => {
    if (cartLength == 0) {
      navigate("/products", { replace: true });
    }
  }, []);
  let user = useSelector((state) => state.userReducer);
  let customer = useSelector((state) => state.customerReducer);

  const [form] = Form.useForm();
  let [firstName, setFirstName] = useState("");
  let [lastName, setlastName] = useState("");
  let [phoneNo, setPhoneNo] = useState("");
  let [countryCode, setCountryCode] = useState("47");
  let [email, setEmail] = useState("");

  //Set initial Form values
  useEffect(() => {
    if (user._id != "") {
      form.setFieldsValue({
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phoneNo: user.phone
      });
      setFirstName(user.firstName);
      setlastName(user.lastName);
      setEmail(user.email);
      setPhoneNo(user.phone);
    }
    if (user._id == "" && customer._id != "") {
      form.setFieldsValue({
        firstname: customer.firstName,
        lastname: customer.lastName,
        email: customer.email,
        phoneNo: customer.phone
      });
      setFirstName(customer.firstName);
      setlastName(customer.lastName);
      setEmail(customer.email);
      setPhoneNo(customer.phone);
    }
  }, [user.firstName, user.lastName, user.email, user.phone]);

  useEffect(() => {
    if (formSet) {
      setFirstName("");
      setlastName("");
      setEmail("");
    }
  }, [formSet]);

  return (
    <>
      <div className="formWrapper" id="checkout">
        <Row className="checkoutRow justify-content-evenly">
          <Col xs={24} sm={9}>
            <Card bordered={false} className="cardContainer">
              <Row className="justify-content-between formHeader">
                <Col>Kundedetaljer</Col>
                <Col>
                  <Tag color="#000" className="border-radius-50 px-3">
                    {" "}
                    {root.orderType == "Take Away" ? "Henting" : "Utkjøring"}
                  </Tag>
                </Col>
              </Row>
              <MainDetail
                className="p-0"
                formSet={formSet}
                setFormValid={setFormValid}
                onAddCustomer={onAddCustomer}
              />
              {/* <Form
                form={form}
                className="mainForm checkoutForm p-3"
                name="basic"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row className="justify-content-between">
                  <Col span={11}>
                    <Form.Item
                      label="Fornavn"
                      name="firstname"
                      className="formField d-flex flex-column"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Fornavn!"
                        }
                      ]}
                    >
                      <Input
                        value={firstName}
                        onChange={onChangeEvent}
                        name="firstName"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Etternavn"
                      name="lastname"
                      className="formField d-flex flex-column"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Etternavn!"
                        }
                      ]}
                    >
                      <Input
                        value={lastName}
                        onChange={onChangeEvent}
                        name="lastName"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="email"
                      label="E-Mail"
                      className="formField d-flex flex-column"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input your E-Mail!"
                        }
                      ]}
                    >
                      <Input
                        name="email"
                        value={email}
                        onChange={onChangeEvent}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Telefonnr"
                      name="phoneNo"
                      className="formField d-flex flex-column"
                    >
                      <Input.Group compact>
                        <Select
                          style={{ width: "30%" }}
                          placeholder="+47"
                          onChange={onChangeCountryCode}
                        >
                          <Option value="47">+47</Option>
                          <Option value="91">+91</Option>
                        </Select>
                        <Input
                          min="0"
                          style={{ width: "70%" }}
                          name="phoneNo"
                          type="number"
                          value={phoneNo}
                          onChange={onChangeEvent}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form> */}

              {root.orderType == "Drive Out" && <MapForm />}
              <Footer
                isEnablePay={enablePay}
                formValid={isformValid}
                onAddCustomer={onAddCustomer}
              />
            </Card>
            {/* </div> */}
          </Col>
          <Col xs={24} sm={10} className="productTotalContainer p-2">
            <SideCart isEnablePay={enablePay} />
          </Col>
        </Row>
      </div>

      {isMobile && <MobileNavBarBottom />}
    </>
  );
};
export default DriveOutCheckout;
