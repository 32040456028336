import React from "react";
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import BottomFooter from "../Footer/BottomFooter";
import coin5 from "../../assets/mobile/coins/coins5.png";
import coin4 from "../../assets/mobile/coins/coins4.webp";
import coin3 from "../../assets/mobile/coins/coins3.webp";
import coin2 from "../../assets/mobile/coins/coins2.webp";

function Modal1Slide() {
  const { modalId } = useParams();
  const navigate = useNavigate();

  // Data for different modals, including images
  const modalData = {
    1: {
      title: "Tjen og innløs TFL-mynter!",
      description: [
        "Samle mynter for hver aktivitet du fullfører på EzyGo.",
        "Bruk myntene dine for å oppfylle dine behov og ønsker.",
        "Tjen, spar og innløs enkelt."
      ],
      image: coin3
    },
    2: {
      title: "Maksimer fordeler med EzyGo AI!",
      description: [
        "Oppdag AI-drevne verktøy for merkevarebygging.",
        "Øk markedsføringsstrategiene dine.",
        "Få tilgang til premium innsikter med EzyGo AI."
      ],
      image: coin4
    },
    3: {
      title: "Fyll posen din med TFL-mynter!",
      description: [
        "Tjen TFL-mynter ved kjøp og aktiviteter.",
        "Innløs mynter for eksklusive rabatter.",
        "Få mer verdi med TFL-belønningene dine."
      ],
      image: coin2
    },
    4: {
      title: "Oppdag mer med EzyGo!",
      description: [
        "Utforsk et bredt spekter av funksjoner.",
        "Slipp løs potensialet i EzyGo-verktøyene.",
        "Kom i gang med suksessreisen din i dag."
      ],
      image: coin5
    },
    5: {
      title: "Voks virksomheten din med EzyGo",
      description: [
        "Forenkle kontantforvaltning og netthandler.",
        "Få merkevareforslag og design.",
        "Bruk numerologi for suksess i merkevarenavnet."
      ],
      image: coin5
    }
  };

  const data = modalData[modalId];

  if (!data) {
    return <div>Ugyldig modal ID</div>;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ background: "#d7f1ff" }}
      >
        <div
          className="text-center"
          style={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "90%",
            maxWidth: "400px",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
          }}
        >
          <img
            src={data.image}
            alt="Modal Graphic"
            style={{ width: "100px" }}
          />
          <h3 style={{ color: "#2d8af7", margin: "20px 0" }}>{data.title}</h3>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
              textAlign: "left"
            }}
          >
            {data.description.map((item, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#444"
                }}
              >
                <span
                  role="img"
                  aria-label="icon"
                  style={{ marginRight: "10px" }}
                >
                  📌
                </span>
                {item}
              </li>
            ))}
          </ul>
          <Button
            variant="primary"
            onClick={() => navigate("/home")}
            style={{
              marginTop: "20px",
              background: "#2d8af7",
              border: "none",
              padding: "10px 20px"
            }}
          >
            Lukke
          </Button>
        </div>
      </div>
      <BottomFooter />
    </>
  );
}

export default Modal1Slide;
