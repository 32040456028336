import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Row, Col, Table, Radio, Card, Button } from "antd";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { saveCoupons } from "../../store/actions/couponAction";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function TflGiftCard(props) {
  const user = useSelector((state) => state.userReducer);
  let navigate = useNavigate("");

  const [show, setShow] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tflGiftCardAmt, setTflGiftCardAmt] = useState("");
  const [couponTotal, setCouponTotal] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { pathname } = useLocation();
  const [confirmCountryCode, setCountryCodeConfirm] = useState("");
  const dispatch = useDispatch();

  // Input references
  const emailRef = useRef("");
  const usernameRef = useRef("");
  // const confirmEmailRef = useRef("");
  const coupanValueRef = useRef("");
  const countrycodeRef = useRef("");
  const phoneNumberRef = useRef("");
  // const confirmphoneNumberRef = useRef("");

  const userId = localStorage.getItem("userId");

  const [errorObj, setErrorObj] = useState({
    emailError: false,
    couponError: false,
    phoneNumberError: false,
    confirmphoneNumberError: false,
    coupanValueError: false,
    conifirmEmailError: false
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Prefill form with logged-in user details
  useEffect(() => {
    if (user) {
      if (user.email) {
        emailRef.current.value = user.email;
      }
      if (user.firstName) {
        usernameRef.current.value = user.firstName;
      }
      if (user.phone) {
        phoneNumberRef.current.value = user.phone;
      }
      if (user.countryCode) {
        countrycodeRef.current.value = user.countryCode;
      }
      // console.log("user", user);
    }
  }, [user]);

  const handleSelect = (value) => {
    // console.log("value", value);
    setSelectedOption(value);
  };

  useEffect(() => {
    const fetchGiftCardData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/treeDrive/tdlcoupon/TFLGiftRange`
        );
        // console.log(response.data.data);
        setTflGiftCardAmt(response.data.data || []);
      } catch (error) {
        console.error("Error fetching Agent card data:", error);
      }
    };

    fetchGiftCardData();
  }, []);

  const onSubmitCoupon = () => {
    let isFormVaild = true;
    setisDisabled(true);
    if (
      emailRef.current.value === "" ||
      !emailRef.current.value.includes("@")
    ) {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, emailError: true }));
    }
    if (phoneNumberRef.current.value.length < 6) {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, phoneNumberError: true }));
    }
    // Check if coupon value is selected
    if (!selectedOption) {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
      // alert("Coupon range ID is required.");
    }

    // Check if coupon value is provided
    if (coupanValueRef.current.value === "") {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
    }

    if (isFormVaild) {
      dispatch(
        saveCoupons({
          recipientName: usernameRef.current.value,
          email: emailRef.current.value,
          confirmEmail: emailRef.current.value,
          phoneNumber: phoneNumberRef.current.value,
          confirmphoneNumber: phoneNumberRef.current.value,
          countryCode: countrycodeRef.current.value,
          couponRangeId: selectedOption,
          customerId: userId || "",
          generatedBy: "EzyGoWeb"
        })
      )
        .then((response) => {
          setisDisabled(false);
          // console.log("response: ", response);
          if (response.message) {
            alert(
              "Du har nådd grensen – tre agentkort er allerede kjøpt!" ||
                response.message
            );
          } else {
            alert("Kupongen ble sendt inn!");
          }
        })
        .catch((error) => {
          setisDisabled(false);
          console.error("Error:", error);
        });
    }
  };
  const getCoupons = async () => {
    const cardHistoryData = {
      customerPhoneNo: user.phone,
      delStatus: "all"
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/getusercoupons`,
        cardHistoryData
      );
      setCouponTotal(response.data.data.totalCoupons);
      // console.log("couponTotal:", response.data.data.totalCoupons);
    } catch (error) {
      console.error("Error fetching coupons:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  const couponDisplay = () => {
    if (couponTotal === 0) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">Start reisen med gavekort!</p>
          <p className="mb-0">Du kan kjøpe opptil 3 gavekort.</p>
        </div>
      );
    } else if (couponTotal === 1) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">Hvorfor stoppe her? Samle mer!</p>
          <p className="mb-0">Du kan legge til 2 gavekort til!</p>
        </div>
      );
    } else if (couponTotal === 2) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">Du har 2 gavekort!</p>
          <p className="mb-0">Men gjett hva? Du kan få 1 til!</p>
        </div>
      );
    }
    // if (couponTotal === 3)
    else {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p>Gratulerer! Du har 3 gavekort!</p>
          {/* <p>Du er ved maksgrensen – du er klar!</p>/ */}
        </div>
      );
    }
    //
  };
  useEffect(() => {
    getCoupons();
  }, []);
  const columns = [
    {
      title: "Agent",
      children: [
        {
          title: "Kjøpsbeløp",
          dataIndex: "giftCardAmt",
          key: "giftCardAmt",
          // width: 60,
          render: (text) => `${text}`
        },
        {
          title: "Mottaksbeløp",
          dataIndex: "finalAmount",
          key: "finalAmount",
          // width: 60,
          render: (text) => `${text}`
        }
      ]
    },

    {
      title: "Månedlig (%) bruk",
      dataIndex: "monthlyPer",
      key: "monthlyPer",
      // width: 50,
      render: (text) => `${text}%`
    },
    {
      title: "Gyldighet (måneder)",
      dataIndex: "validity",
      key: "validity",
      // width: 50,
      render: (text) => `${text}`
    },
    {
      title: <CheckCircleOutlined />,
      // title: "Velge",
      key: "select",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Radio
          value={record._id}
          checked={selectedOption === record._id}
          onChange={() => handleSelect(record._id)}
        />
      )
    }
  ];
  const dataSource = Array.isArray(tflGiftCardAmt)
    ? tflGiftCardAmt.map((item) => ({
        ...item,
        key: item._id // Use _id as the unique key for each row
      }))
    : [];
  // console.log("dataSource", dataSource);

  const content = () => {
    return (
      <>
        <Row className="pb-4">
          <Card className="border-radius-10 width-100">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Navn</Form.Label>
                <Form.Control type="email" ref={usernameRef} />
                {errorObj.usernameError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst skriv inn en gyldig name
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>E-post</Form.Label>
                <Form.Control type="email" ref={emailRef} />
                {errorObj.emailError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst skriv inn en gyldig e-mail adresse
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Telefonnummer</Form.Label>
                <div className="d-flex ">
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    ref={countrycodeRef}
                    onChange={(event) => {
                      setCountryCodeConfirm(event.target.value);
                    }}
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    ref={phoneNumberRef}
                    type="number"
                  />
                </div>
                {errorObj.phoneNumberError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst oppgi et gyldig telefonnummer.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Gavekortverdi</Form.Label>

                <Table
                  bordered
                  columns={columns}
                  dataSource={dataSource} // Correctly mapped data source
                  scroll={{ x: 500, y: 620 }}
                  locale={{
                    emptyText: "No data available" // Custom message when no data exists
                  }}
                  pagination={false} // Disable pagination if not required
                  className="tflTable"
                />
                {errorObj.coupanValueError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst velg en gyldig gavekortverdi.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
            <p></p>
            <div className="card-footer text-center">
              <Button
                variant="primary"
                onClick={onSubmitCoupon}
                className="btn-primary border-radius-5"
              >
                Kjøp gavekort
              </Button>
              {couponDisplay()}
            </div>
          </Card>
        </Row>
      </>
    );
  };

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {isMobile ? (
        <div className="px-2 pt-3 pb-5 justify-content-start align-items-start giftCardFormBg vh90andScroll ">
          <Row className="justify-content-center">
            <h2 className="font-20 text-white">Kjøp gavekort</h2>
          </Row>
          {content()}
        </div>
      ) : (
        <div style={{ marginTop: "80px" }} className="mission profilePages">
          <Row className="justify-content-center">
            <h2 className="font-20">Kjøp gavekort</h2>
          </Row>
          <Row className="align-items-center justify-content-center ">
            <Col lg={12} className="">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}

export default TflGiftCard;
