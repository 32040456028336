import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Layout,
  List,
  Row,
  Col,
  Space,
  Typography
} from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  CreditCardOutlined,
  EyeOutlined,
  FileProtectOutlined,
  FileOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { s3url } from "../../helper/endpoint";
import { EditOutlined, CaretRightOutlined } from "@ant-design/icons";
import { userAction } from "../../store/slices/userslices";
import ProfileIcon from "../../assets/profileFallBack.png";

const { Text, Title } = Typography;

const ProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userprofileImage = localStorage.getItem("profileImage");
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const profileImageSrc =
    userprofileImage == "" ? ProfileIcon : `${s3url}/${userprofileImage}`;
  let user = useSelector((state) => state.userReducer);

  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());
    if (!user.token) {
      navigate("/login");
    }
    window.location.reload();
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  const profileSections = [
    {
      title: "Arkiv",
      items: [
        {
          icon: <EditOutlined />,
          text: "Rediger profil",
          path: "/editProfile"
        },
        {
          icon: <FileTextOutlined />,
          text: "Bestillingshistorikk",
          path: "/orderHistory"
        }
      ]
    },
    {
      title: "Agentkort",
      items: [
        {
          icon: <CreditCardOutlined />,
          text: "Kjøp til deg selv",
          path: "/getTflCouponforme"
        },
        {
          icon: <UserOutlined />,
          text: "Kjøp for andre",
          path: "/getTflCouponforothers"
        },
        {
          icon: <EyeOutlined />,
          text: "Se Agent Kort",
          path: "/showTflGiftCard"
        }
      ]
    },
    {
      title: "Mer informasjon",
      items: [
        {
          icon: <FileProtectOutlined />,
          text: "Personvernerklæring",
          path: "/home"
        },
        { icon: <FileOutlined />, text: "Vilkår og betingelser", path: "/home" }
      ]
    }
  ];
  return (
    <Layout className="shortCutStyle px-3 py-3">
      <Card
        className=" mb-3 commonShadowAndRound"
        title={
          <>
            <span></span>
            <Button
              className="border-radius-5"
              type="primary"
              onClick={() => navigate("/home")}
            >
              Tilbake
            </Button>
          </>
        }
      >
        <Row className="d-flex justify-content-between">
          <Col span={6}>
            <Avatar
              size={100}
              src={profileImageSrc}
              style={{ marginBottom: "10px" }}
              alt={`${user.firstName}`}
            />
          </Col>
          <Col
            span={15}
            className="flex-column d-flex align-items-start justify-content-center"
          >
            <Title level={4}>
              {capitalizeFirstLetter(user.firstName)}{" "}
              {capitalizeFirstLetter(user.lastName)}!👋
            </Title>
            <p>{user.email}</p>
          </Col>
        </Row>
      </Card>
      {profileSections.map((section, index) => (
        <Card
          key={index}
          style={{ marginBottom: "16px" }}
          className=" commonShadowAndRound"
        >
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
            {section.title}
          </Typography.Title>
          <List
            dataSource={section.items}
            renderItem={(item) => (
              <List.Item className="d-flex">
                <NavLink
                  to={item.path}
                  style={{ textDecoration: "none" }}
                  className={
                    "d-flex justify-content-between width-100 text-black"
                  }
                >
                  <div>
                    {" "}
                    {item.icon}
                    <Text>{item.text}</Text>
                  </div>
                  <CaretRightOutlined />
                </NavLink>
              </List.Item>
            )}
          />
        </Card>
      ))}
      <Card
        style={{ textAlign: "center", marginTop: "16px" }}
        className=" commonShadowAndRound"
      >
        <Button
          onClick={refreshAfterLogout}
          type="primary"
          danger
          icon={<LogoutOutlined />}
          size="large"
          style={{ width: "100%", borderRadius: "4px" }}
        >
          Logg ut
        </Button>
      </Card>
    </Layout>
  );
};

export default ProfileScreen;
