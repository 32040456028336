import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Card, Col, Button } from "antd";
import { s3url, apiUrl } from "../../helper/endpoint";
import MapForm from "../Checkout/Forms/MapForm";
import axios from "axios";
import BottomFooter from "../Footer/BottomFooter";

const TflMarketCart = (props) => {
  let location = useLocation();
  let customerLocation = useSelector(
    (state) => state.locationReducer.locationAddress
  );
  const { totalAmount, totalWeight } = location.state;

  const [cartData, setCartData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [totalToPay, setTotalToPay] = useState(totalAmount);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  let userId = localStorage.getItem("userId");
  let userFirstName = localStorage.getItem("userFirstName");
  let userLastName = localStorage.getItem("userLastName");
  let userEmail = localStorage.getItem("userEmail");
  let userCountryCode = localStorage.getItem("userCountryCode");
  let userPhone = localStorage.getItem("userPhone");
  const token = localStorage.getItem("token");

  useEffect(() => {
    let data = localStorage.getItem("TFLCartData");
    if (data) {
      setCartData(JSON.parse(data));
    }
    if (location.state && location.state.deliveryCharges) {
      const { deliveryCharges, totalLength, totalBreadth, totalHeight } =
        location.state;

      let prices = [];
      let allPrices = [];
      for (let i = 0; i < deliveryCharges.length; i++) {
        allPrices.push(deliveryCharges[i].price);

        if (
          totalLength <= deliveryCharges[i].dimensions.length &&
          totalBreadth <= deliveryCharges[i].dimensions.breadth &&
          totalHeight <= deliveryCharges[i].dimensions.height
        ) {
          if (
            totalWeight >= deliveryCharges[i].weight.min &&
            totalWeight <= deliveryCharges[i].weight.max
          ) {
            prices.push(deliveryCharges[i].price);
          }
        }
      }

      let charge =
        allPrices.length > 0 ? Math.min(...allPrices) : Math.max(...prices);

      setDeliveryCharges(charge);
    }
  }, [location.state, totalToPay]);

  useEffect(() => {
    let totalAfterDeliveryCharges = totalToPay + deliveryCharges;
    setTotalToPay(totalAfterDeliveryCharges);
  }, [deliveryCharges]);

  let onCreateOrder = async (cart) => {
    setDisabled(true);
    let orderPayload = {
      items: cartData,
      customerData: {
        countryCode: userCountryCode,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhone,
        address: customerLocation
      },
      appUserId: userId,
      customerType: "IOS",
      orderFrom: "TDP",
      paymentMode: "card"
    };

    try {
      let response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/createordertfl`,
        orderPayload,
        {
          headers: {
            "x-auth-token": `${token}`
          }
        }
      );
      if (response.status === 200) {
        setDisabled(false);
        let orderResponseUrl = response.data.data.data.url;
        window.location.replace(orderResponseUrl);
      }
    } catch (error) {
      setDisabled(true);
    }
  };

  return (
    <>
      <Row className="px-3 py-2 justify-content-between align-items-center">
        <Col span={20}>
          <MapForm />
        </Col>
        <Col
          span={4}
          className="d-flex justify-content-end align-items-center"
          style={{
            fontWeight: "bold",
            color: "#1890ff",
            cursor: "pointer"
          }}
          onClick={() => window.history.back()}
        >
          Back
        </Col>
      </Row>
      <Row className="px-3">
        <Col
          span={24}
          className="mt-3"
          style={{
            height: "40vh",
            overflowY: "auto",
            borderRadius: "10px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            backgroundColor: "#f9f9f9"
          }}
        >
          {cartData.map((item) => (
            <Card
              key={item._id}
              className="mb-3 border-radius-10 tflMarketProCard"
              style={{
                border: "1px solid #eaeaea",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Row className="d-flex justify-content-between align-items-center">
                <Col span={6}>
                  <img
                    style={{
                      width: "60px",
                      borderRadius: "5px",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                    }}
                    alt="example"
                    src={`${s3url}/${item.url}`}
                  />
                </Col>
                <Col span={17} className="pl-3">
                  <p className="font-18 font-700 mb-0">
                    {item.productName} &times; {item.quantity}
                  </p>
                  <p style={{ margin: "5px 0 0", color: "#555" }}>
                    {item.amount * item.quantity} Kr.
                  </p>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span={24}>
          <Card
            className="mb-3 border-radius-10"
            style={{
              border: "1px solid #eaeaea",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
                color: "#333",
                marginBottom: "15px"
              }}
            >
              Regningsdetaljer:
            </h5>
            <div className="d-flex justify-content-between">
              <p style={{ margin: 0, color: "#555" }}>Total</p>
              <p style={{ margin: 0, fontWeight: "bold" }}>{totalAmount} Kr.</p>
            </div>
            <div className="d-flex justify-content-between">
              <p style={{ margin: 0, color: "#555" }}>Frakt Pris</p>
              <p style={{ margin: 0, fontWeight: "bold" }}>
                +{deliveryCharges} Kr.
              </p>
            </div>
            <Button
              type="primary"
              className="w-100 saveBtn"
              style={{
                backgroundColor: "#1890ff",
                color: "#fff",
                fontWeight: "bold",
                padding: "10px 20px",
                fontSize: "16px",
                borderRadius: "5px"
              }}
              block
              onClick={() => onCreateOrder(cartData)}
              disabled={disabled}
            >
              Pay {totalToPay} Kr.
            </Button>
          </Card>
        </Col>
      </Row>
      <BottomFooter />
    </>
  );
};

export default TflMarketCart;
